import React from "react";
import { useSelector } from "react-redux";
import "./navBar.scss";
import { useNavigate, useLocation } from "react-router-dom";
import SVG from "../svg/svg";
import AvatarMenu from "./avatarMenu/avatarMenu";
import Permissions from "../../permissions/permissions";
import { DIVIDER_ICON, LOGO_ICON } from "../../constants/common.constants";
import Tooltip, { tooltipTypes } from "../tooltip/tooltip";
import getInitials, { buildUrl } from "../../utils/string.utils";
import {
  INCIDENTS_URL,
  ORGANIZATIONS_DETAILS_URL,
  ORGANIZATIONS_URL,
  PANTHER_INCIDENTS_URL,
  SENSORS_URL,
  USERS_URL
} from "../../constants/urls.constants";

function getActiveClass(page = "", currentPage = ""): string {
  return currentPage && currentPage.includes(page) ? "active" : "";
}

const ICON_CLASSES = Object.freeze({
  dashboard: "navBarDashboard",
  incidents: "navBarIncidents",
  pantherEvents: "navBarPantherEvents",
  organizations: "navBarOrganizations",
  users: "navBarUsers",
  sensors: "navBarSensors",
  notifications: "navBarNotifications",
  active: "active"
});

export default function NavBar() {
  const localUser = useSelector((state: any) => state.user.profile);

  const initials = getInitials(localUser.name);

  const orgId = useSelector((state: any) => state.user.orgId);

  const { Pages } = Permissions();

  const showUsers = Pages.users.canNavigate(localUser.role);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const onClickOrganizations = () => {
    if (Pages.organizations.canNavigate(localUser.role)) {
      navigate(buildUrl(ORGANIZATIONS_URL));
    } else {
      navigate(buildUrl(ORGANIZATIONS_DETAILS_URL, orgId));
    }
  };

  const onClickNavItem =
    (path = "") =>
    () =>
      navigate(buildUrl(path));

  return (
    <section className="navBarContainer">
      <img
        className="navBar__brand-logo"
        onClick={onClickNavItem(INCIDENTS_URL)}
        src={LOGO_ICON}
        alt="Soteria Defense MDR"
      />
      <nav className="navBarActions">
        <div
          className={`has-tooltip ${
            ICON_CLASSES.pantherEvents
          } ${getActiveClass("-incidents", pathname)}`}
        >
          <SVG
            onClick={onClickNavItem(PANTHER_INCIDENTS_URL)}
            file="panther_logo-exported.svg"
          />
          <Tooltip type={tooltipTypes.info} text="SIEM Events" />
        </div>
        <div
          className={`has-tooltip ${ICON_CLASSES.incidents} ${getActiveClass(
            "/incidents",
            pathname
          )}`}
        >
          <SVG onClick={onClickNavItem(INCIDENTS_URL)} file="incidents.svg" />
          <Tooltip type={tooltipTypes.info} text="Incidents" />
        </div>
        <div
          className={`has-tooltip ${
            ICON_CLASSES.organizations
          } ${getActiveClass("organizations", pathname)}`}
        >
          <SVG onClick={onClickOrganizations} file="organizations.svg" />
          <Tooltip type={tooltipTypes.info} text="Organizations" />
        </div>
        {showUsers && (
          <div
            className={`has-tooltip ${ICON_CLASSES.users} ${getActiveClass(
              "users",
              pathname
            )}`}
          >
            <SVG onClick={onClickNavItem(USERS_URL)} file="users.svg" />
            <Tooltip type={tooltipTypes.info} text="Users" />
          </div>
        )}

        <div
          className={`has-tooltip ${ICON_CLASSES.sensors} ${getActiveClass(
            "sensors",
            pathname
          )}`}
        >
          <SVG onClick={onClickNavItem(SENSORS_URL)} file="sensors.svg" />
          <Tooltip type={tooltipTypes.info} text="Sensors" />
        </div>
        <img className="navBarDivider" src={DIVIDER_ICON} alt="" />
        <AvatarMenu user={initials} />
      </nav>
    </section>
  );
}
