import React, { useCallback, useContext, useMemo, useState } from "react";
import "./analystNotes.scss";
import Icon from "../../components/icon/icon";
import TextArea from "../../components/textArea/textArea";
import {
  ADD_ICON,
  ERROR_ICON,
  MESSAGE,
  SUCCESS_ICON,
  VARIANT,
  WIDTH
} from "../../constants/common.constants";
import useEvent from "../useEvent";
import Button from "../../components/button/button";
import ModalContext from "../../contexts/modal.context";
import SnackbarContext from "../../contexts/snackbar.context";
import {
  IncidentActivityContext,
  IncidentContext
} from "../../incident/incident";
import { IAnalystNote } from "../event.types";
import Tooltip from "../../components/tooltip/tooltip";

type IAnalystNotesProps = { eventId: string; data: IAnalystNote };

export default function AnalystNotes({ eventId, data }: IAnalystNotesProps) {
  const { id: incidentId } = useContext(IncidentContext);
  const { showModal, closeModal, setAsyncLoading } = useContext(ModalContext);
  const { showSnackbar } = useContext(SnackbarContext);

  const { deleteAnalystNotes, updateAnalystNotes } = useEvent(eventId);

  const [noteText, setNoteText] = useState(data?.Note || "");
  const [text, setText] = useState(data?.Note || "");

  const deleteIconURL = `${process.env.REACT_APP_CDN_IMAGES}/delete.svg`;

  const { notesDisabled } = useContext(IncidentActivityContext);

  const [isLoading, setIsLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const deleteNote = useCallback(() => {
    setAsyncLoading(true);

    deleteAnalystNotes()
      .then(() => {
        setAsyncLoading(false);
        setIsEditing(false);
        closeModal();
        showSnackbar({
          text: "Analyst note deleted",
          type: MESSAGE.info,
          icon: SUCCESS_ICON
        });
        setNoteText("");
        setText("");
      })
      .catch((error) => {
        console.error(
          `Error deleting analyst note in event ${eventId} of incident ${incidentId}. Status ${error.status}. ${error}`
        );
        setAsyncLoading(false);
        closeModal();
        showSnackbar({
          text: "Error deleting analyst note",
          type: MESSAGE.error,
          icon: ERROR_ICON
        });
      });
  }, [
    closeModal,
    deleteAnalystNotes,
    eventId,
    incidentId,
    setAsyncLoading,
    showSnackbar
  ]);

  const onClickDelete = useCallback(
    () =>
      showModal({
        title: "Delete Analyst note",
        clickAction: deleteNote,
        actionText: "Yes, delete",
        content: <p>Are you sure you want to delete the Analyst note?</p>
      }),
    [deleteNote, showModal]
  );

  const onClickCancel = useCallback(() => {
    setText(noteText);
    setIsEditing(false);
  }, [noteText]);

  const onClickSave = useCallback(() => {
    setIsLoading(true);

    updateAnalystNotes(text)
      .then(() => {
        setIsLoading(false);
        setIsEditing(false);
        showSnackbar({
          text: "Analyst note saved",
          type: "info",
          icon: SUCCESS_ICON
        });
        setNoteText(text);
      })
      .catch((error: any) => {
        console.error(
          `Error saving analyst note in event ${eventId} of incident ${incidentId}. Status ${error.status}. ${error}`
        );
        setIsLoading(false);
        showSnackbar({
          text: "Error saving analyst note",
          type: "error",
          icon: ERROR_ICON
        });
      });
  }, [eventId, incidentId, showSnackbar, text, updateAnalystNotes]);

  const onTextChange = (event: any) => setText(event.target.value);

  const analystNoteContent = useMemo(
    () =>
      notesDisabled || !isEditing ? (
        <div
          className="has-tooltip analystNotesContainer__details__paragraph"
          onClick={() => !notesDisabled && setIsEditing(true)}
        >
          {!notesDisabled && <Tooltip text="Click to edit the note" top />}
          <p className="analystNotesContainer__details__paragraph_content">
            {noteText}
          </p>
        </div>
      ) : (
        <>
          <TextArea
            value={text}
            width={WIDTH.max}
            disabled={isLoading}
            rows={2}
            onChange={onTextChange}
          />
          <section className="analystNotesContainer__actions">
            <Button variant={VARIANT.secondary} onClick={onClickCancel}>
              Cancel
            </Button>
            <Button
              variant={VARIANT.primary}
              onClick={onClickSave}
              loading={isLoading}
              disabled={!text.length}
            >
              Save
            </Button>
          </section>
        </>
      ),
    [
      isEditing,
      isLoading,
      noteText,
      notesDisabled,
      onClickCancel,
      onClickSave,
      text
    ]
  );

  return (
    <div className="analystNotesContainer">
      <section className="analystNotesContainer__header">
        {!!noteText?.length || isEditing ? (
          <p>Analyst note</p>
        ) : (
          !notesDisabled && (
            <div className="analystNotesContainer__header__add">
              <img src={ADD_ICON} alt="" />
              <p onClick={() => setIsEditing(true)}>Add analyst note</p>
            </div>
          )
        )}
        {!notesDisabled && !isEditing && !!noteText?.length && (
          <div className="has-tooltip">
            <Tooltip text="Delete the note" top right />
            <Icon image={deleteIconURL} alt="delete" onClick={onClickDelete} />
          </div>
        )}
      </section>
      <section className="analystNotesContainer__details">
        {analystNoteContent}
      </section>
    </div>
  );
}
