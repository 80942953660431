/* eslint-disable max-classes-per-file */
import { useSelector } from "react-redux";

class ApiException extends Error {
  status: number | null = null;

  constructor(message: string, status: number) {
    super(message);
    this.status = status;
  }
}

class Options {
  method = "";

  token = "";

  body: any;

  buildHeaders(): any {
    const headers = new Headers();
    if (this.token?.length)
      headers.append("Authorization", `Bearer ${this.token}`);
    return headers;
  }

  setMethod(value: string): void {
    this.method = value;
  }

  setBody(value: any): void {
    this.body = JSON.stringify(value);
  }

  setToken(value: string): void {
    this.token = value;
  }

  getOptions(): any {
    return {
      method: this.method,
      headers: this.buildHeaders.bind(this)(),
      ...(this.body ? { body: this.body } : {})
    };
  }
}

export class Request extends Options {
  endpoint = "";

  setEndpoint(value: string) {
    this.endpoint = value;
  }

  async fetchFromEndpoint(options: Options) {
    const response = await fetch(this.endpoint, options);

    if (!response.ok) {
      const error = await response.text();
      throw new ApiException(error, response.status);
    }
    return response;
  }

  async run(options: Options) {
    const response = await this.fetchFromEndpoint.bind(this)(options);
    const data = await response.json();

    if (data !== null && data !== undefined) {
      return data;
    }
    throw new ApiException("No data returned", response.status);
  }

  async runWithStatus(options: Options) {
    const response = await this.fetchFromEndpoint.bind(this)(options);
    const data = await response.json();

    if (data !== null && data !== undefined) {
      return { data, status: response.status };
    }
    throw new ApiException("No data returned", response.status);
  }

  async runReturnText(options: Options) {
    const response = await this.fetchFromEndpoint.bind(this)(options);
    const data = await response.text();

    if (data !== null && data !== undefined) {
      return data;
    }
    throw new ApiException("No data returned", response.status);
  }

  async runReturnResponse(options: Options) {
    // eslint-disable-next-line no-return-await
    return await this.fetchFromEndpoint.bind(this)(options);
  }
}

function useApi(): Request {
  const request: Request = new Request();
  const token = useSelector((state: any) => state.user.token);
  request.setToken(token);

  return request;
}

export function useGet() {
  const getApi = useApi();
  getApi.setMethod("GET");
  return getApi;
}

export function usePost() {
  const postApi = useApi();
  postApi.setMethod("POST");
  return postApi;
}

export function usePatch() {
  const patchApi = useApi();
  patchApi.setMethod("PATCH");
  return patchApi;
}

export function useDelete() {
  const deleteApi = useApi();
  deleteApi.setMethod("DELETE");
  return deleteApi;
}
