import { Request, useGet, usePatch } from "./api";

export function useGetIncidents() {
  const getApi: Request = useGet();

  async function run(parameters = "") {
    const endpoint = `${
      parameters.length
        ? `${process.env.REACT_APP_API_INCIDENTS}?${parameters}`
        : process.env.REACT_APP_API_INCIDENTS
    }`;
    getApi.setEndpoint(endpoint);
    return getApi.runReturnResponse(getApi.getOptions());
  }
  return run;
}

export function useGetPantherNextIncidentStatus() {
  const getApi: Request = useGet();

  async function run(id: string) {
    getApi.setEndpoint(
      `${process.env.REACT_APP_API_PANTHER_INCIDENTS}/${id}/nextstatus`
    );
    return getApi.run(getApi.getOptions());
  }
  return run;
}

export function useGetAllIncidents() {
  const getApi: Request = useGet();

  async function run(parameters = "") {
    const endpoint = `${process.env.REACT_APP_API_INCIDENTS}/csv${
      parameters.length ? `?${parameters}` : ""
    }`;
    getApi.setEndpoint(endpoint);
    return getApi.runReturnText(getApi.getOptions());
  }
  return run;
}

export function useGetIncident() {
  const getApi: Request = useGet();

  async function run(id: string) {
    getApi.setEndpoint(`${process.env.REACT_APP_API_INCIDENTS}/${id}`);
    return getApi.run(getApi.getOptions());
  }
  return run;
}

export function useGetNextIncidentStatus() {
  const getApi: Request = useGet();

  async function run(id: string) {
    getApi.setEndpoint(
      `${process.env.REACT_APP_API_INCIDENTS}/${id}/nextstatus`
    );
    return getApi.run(getApi.getOptions());
  }
  return run;
}

export function useAssignIncident() {
  const patchApi: Request = usePatch();

  async function run(id: string, body: any) {
    patchApi.setEndpoint(`${process.env.REACT_APP_API_INCIDENTS}/${id}`);
    patchApi.setBody(body);
    return patchApi.run(patchApi.getOptions());
  }
  return run;
}

export function usePantherAssignIncident() {
  const patchApi: Request = usePatch();

  async function run(id: string, body: any) {
    patchApi.setEndpoint(
      `${process.env.REACT_APP_API_PANTHER_INCIDENTS}/${id}`
    );
    patchApi.setBody(body);
    return patchApi.run(patchApi.getOptions());
  }
  return run;
}

export function useUpdateIncident() {
  const patchApi: Request = usePatch();

  async function run(id: string, body: any) {
    patchApi.setEndpoint(`${process.env.REACT_APP_API_INCIDENTS}/${id}`);
    patchApi.setBody(body);
    return patchApi.run(patchApi.getOptions());
  }
  return run;
}

export function useGetIncidentCatalog() {
  const getApi: Request = useGet();

  async function run() {
    getApi.setEndpoint(`${process.env.REACT_APP_API_INCIDENTS}/catalog`);
    return getApi.run(getApi.getOptions());
  }
  return run;
}

export function useGetIncidentsFilters() {
  const getApi: Request = useGet();

  async function run(parameters = "") {
    getApi.setEndpoint(
      `${process.env.REACT_APP_API_INCIDENTS_FILTERS}${parameters}`
    );
    return getApi.run(getApi.getOptions());
  }
  return run;
}

// PANTHER EVENT API

export function usePantherUpdateIncident() {
  const patchApi: Request = usePatch();

  async function run(id: string, body: any) {
    patchApi.setEndpoint(`${process.env.REACT_APP_API_INCIDENTS}/${id}`);
    patchApi.setBody(body);
    return patchApi.run(patchApi.getOptions());
  }
  return run;
}

export function useGetPantherIncidents() {
  const getApi: Request = useGet();

  async function run(parameters = "") {
    const endpoint = `${
      parameters.length
        ? `${process.env.REACT_APP_API_PANTHER_INCIDENTS}?${parameters}`
        : process.env.REACT_APP_API_PANTHER_INCIDENTS
    }`;
    getApi.setEndpoint(endpoint);
    return getApi.runReturnResponse(getApi.getOptions());
  }
  return run;
}

export function useGetNextPantherIncidentStatus() {
  const getApi: Request = useGet();

  async function run(id: string) {
    getApi.setEndpoint(
      `${process.env.REACT_APP_API_PANTHER_INCIDENTS}/${id}/nextstatus`
    );
    return getApi.run(getApi.getOptions());
  }
  return run;
}

export function useUpdatePantherIncident() {
  const patchApi: Request = usePatch();

  async function run(id: string, body: any) {
    patchApi.setEndpoint(
      `${process.env.REACT_APP_API_PANTHER_INCIDENTS}/${id}`
    );
    patchApi.setBody(body);
    return patchApi.run(patchApi.getOptions());
  }
  return run;
}

export function useUpdatePantherIncidentByOrgId() {
  const patchApi: Request = usePatch();

  async function run(org_id: string, body: any) {
    patchApi.setEndpoint(
      `${process.env.REACT_APP_API_PANTHER_INCIDENTS}/close/${org_id}`
    );
    patchApi.setBody(body);
    return patchApi.runWithStatus(patchApi.getOptions());
  }
  return run;
}

export function useAssignPantherIncident() {
  const patchApi: Request = usePatch();

  async function run(id: string, body: any) {
    patchApi.setEndpoint(
      `${process.env.REACT_APP_API_PANTHER_INCIDENTS}/${id}`
    );
    patchApi.setBody(body);
    return patchApi.run(patchApi.getOptions());
  }
  return run;
}

export function useGetPantherIncidentsFilters() {
  const getApi: Request = useGet();

  async function run(parameters = "") {
    getApi.setEndpoint(
      `${process.env.REACT_APP_API_PANTHER_INCIDENTS}/filters${parameters}`
    );
    return getApi.run(getApi.getOptions());
  }
  return run;
}

export function useGetPantherIncidentCatalog() {
  const getApi: Request = useGet();

  async function run() {
    getApi.setEndpoint(
      `${process.env.REACT_APP_API_PANTHER_INCIDENTS}/catalog`
    );
    return getApi.run(getApi.getOptions());
  }
  return run;
}

export function useGetPantherIncident() {
  const getApi: Request = useGet();

  async function run(id: string) {
    getApi.setEndpoint(`${process.env.REACT_APP_API_PANTHER_INCIDENTS}/${id}`);
    return getApi.run(getApi.getOptions());
  }
  return run;
}
