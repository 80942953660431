import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef
} from "react";
import "./incidentsTableColumns.scss";
import { useSelector } from "react-redux";
import Avatar from "../../components/avatar/avatar";
import {
  ACTIONS,
  CHECK,
  EVENTS,
  RISK,
  SKELETON_VARIANT,
  STATE,
  STATUS,
  TABLE,
  VARIANT
} from "../../constants/common.constants";
import IncidentsContextMenu from "./incidentsContextMenu";
import Tooltip, { tooltipTypes } from "../../components/tooltip/tooltip";
import Chip from "../../components/chip/chip";
import Menu, { IMenuItem } from "../../components/menu/menu";
import { handlersMapper } from "../../mappers/incidentListMappers";
import FormatDate from "../../formatDate/formatDate";
import { IIncidentsListTableColumns } from "../incident-list.types";
import { buildUrl } from "../../utils/string.utils";
import {
  INCIDENT_URL,
  PANTHER_INCIDENT_URL
} from "../../constants/urls.constants";
import SessionContext from "../../contexts/session.context";
import IPantherIncidentListContext from "../../contexts/panther-incident-list.context";
import { IPantherEvent } from "../../contexts/types/incident.list.types";
import featureFlagsContext from "../../contexts/feature-flags.provider";

function IncidentTableHeaderCheckbox() {
  const { selectedIncidents, onCheckAllIncidents, incidents } = useContext(
    IPantherIncidentListContext
  );
  const allCheck = useRef<any>({});

  useEffect(() => {
    const selectedLength = selectedIncidents.length;
    const selectableLength = (incidents as IPantherEvent[]).filter(
      (incident: IPantherEvent) => incident.can_be_closed
    ).length;

    if (allCheck.current) {
      if (selectedLength === 0) {
        allCheck.current.checked = false;
        allCheck.current.indeterminate = false;
      } else if (selectedLength === selectableLength) {
        allCheck.current.checked = true;
        allCheck.current.indeterminate = false;
      } else {
        allCheck.current.checked = false;
        allCheck.current.indeterminate = true;
      }
    }
  }, [incidents, selectedIncidents]);

  const handleChange = useCallback(() => {
    if (allCheck.current) {
      const isChecked = allCheck.current.checked;
      onCheckAllIncidents(isChecked);
    }
  }, [onCheckAllIncidents]);

  return <input type="checkbox" ref={allCheck} onChange={handleChange} />;
}

export default function useIncidentsTableColumns(): IIncidentsListTableColumns {
  const headerCheck = useRef<any>({});
  const { enableBatchClose } = useContext(featureFlagsContext);
  const { isMdrRole } = useContext(SessionContext);
  const localUser = useSelector((state: any) => state.user.profile);

  const setHeaderCheckState = useCallback((state: string) => {
    const value: any = {
      [CHECK.checked]: () => {
        headerCheck.current.checked = true;
        headerCheck.current.indeterminate = false;
      },
      [CHECK.unchecked]: () => {
        headerCheck.current.checked = false;
        headerCheck.current.indeterminate = false;
      },
      [CHECK.indeterminate]: () => {
        headerCheck.current.checked = false;
        headerCheck.current.indeterminate = true;
      }
    };

    if (headerCheck.current) value[state]();
  }, []);

  const buildRedirectURL = useCallback(
    (id: string, isSIEM = false) =>
      buildUrl(isSIEM ? PANTHER_INCIDENT_URL : INCIDENT_URL, id),
    []
  );

  const buildCell = useCallback(
    (row: any, value: string, isSIEM = false) => {
      const url = buildRedirectURL(row.values.id, isSIEM);
      return (
        <a target="_blank" href={url} rel="noreferrer" className="noDecoration">
          {value}
        </a>
      );
    },
    [buildRedirectURL]
  );

  const buildSeverityCell = useCallback(
    (row: any, value: string, isSIEM = false) => {
      const text = value?.length ? value : "-";
      const className = `incidentsSeverityBar incidentsSeverityBar--${String(
        value
      ).toLowerCase()}`;
      const url = buildRedirectURL(row.values.id, isSIEM);

      return (
        <a
          target="_blank"
          href={url}
          rel="noreferrer"
          className="incidentsSeverityCategoryContainer noDecoration"
        >
          {value.length ? <div className={className} /> : null}
          {text}
        </a>
      );
    },
    [buildRedirectURL]
  );

  const buildCategoryCell = useCallback(
    (row: any, value: string, isSIEM = false) => {
      const type: any = {
        [RISK.dismissed]: `${process.env.REACT_APP_CDN_IMAGES}/information.svg`,
        [RISK.malicious]: `${process.env.REACT_APP_CDN_IMAGES}/error.svg`,
        [RISK.risky]: `${process.env.REACT_APP_CDN_IMAGES}/risk.svg`,
        [RISK.suspicious]: `${process.env.REACT_APP_CDN_IMAGES}/warningCircle.svg`,
        default: `${process.env.REACT_APP_CDN_IMAGES}/unknown.svg`
      };

      const { risk } = row.values;

      const icon = risk.length
        ? type[risk.toLowerCase()] || type.default
        : type.default;

      const text = value.length ? value : "-";
      const url = buildRedirectURL(row.values.id, isSIEM);

      return (
        <a
          className="incidentsSeverityCategoryContainer noDecoration"
          target="_blank"
          href={url}
          rel="noreferrer"
        >
          {value.length ? (
            <img
              className="incidentsSeverityCategoryContainer__icon"
              src={icon}
              alt={value}
            />
          ) : null}
          {text}
        </a>
      );
    },
    [buildRedirectURL]
  );

  const buildStatusCell = useCallback(
    (row: any, value: string, isSIEM = false) => {
      const type: any = {
        [STATUS.new]: STATUS.new,
        [STATUS.claimed]: "Assigned",
        [STATUS.closed]: STATUS.closed,
        [STATUS.internalReview]: "Review",
        [STATUS.clientEscalation]: isMdrRole ? "Sent" : STATUS.new,
        [STATUS.clientViewed]: "Viewed",
        default: "Unknown"
      };

      const text = type[value] || type.default;

      const url = buildRedirectURL(row.values.id, isSIEM);

      return (
        <a
          className="incidentsSeverityCategoryContainer noDecoration"
          target="_blank"
          href={url}
          rel="noreferrer"
        >
          <Chip label={text} variant={VARIANT.status} status={text} />
        </a>
      );
    },
    [buildRedirectURL, isMdrRole]
  );

  const clientStructure = useMemo(
    () => [
      {
        Header: TABLE.INCIDENTS.CELLS.CLIENT_USERS.severity.name,
        accessor: TABLE.INCIDENTS.CELLS.CLIENT_USERS.severity.accessor,
        Cell: ({ row, value }: any) => buildSeverityCell(row, value),
        type: SKELETON_VARIANT.text,
        visible: "true"
      },
      {
        Header: TABLE.INCIDENTS.CELLS.CLIENT_USERS.category.name,
        accessor: TABLE.INCIDENTS.CELLS.CLIENT_USERS.category.accessor,
        Cell: ({ row, value }: any) => buildCategoryCell(row, value),
        type: SKELETON_VARIANT.text,
        filter: "equals",
        visible: "true"
      },
      {
        Header: TABLE.INCIDENTS.CELLS.CLIENT_USERS.title.name,
        accessor: TABLE.INCIDENTS.CELLS.CLIENT_USERS.title.accessor,
        filter: "equals",
        type: SKELETON_VARIANT.text,
        Cell: ({ row, value }: any) => buildCell(row, value),
        visible: "true"
      },
      {
        Header: TABLE.INCIDENTS.CELLS.CLIENT_USERS.hostname.name,
        accessor: TABLE.INCIDENTS.CELLS.CLIENT_USERS.hostname.accessor,
        type: SKELETON_VARIANT.text,
        Cell: ({ row, value }: any) => buildCell(row, value),
        visible: "true"
      },
      {
        Header: TABLE.INCIDENTS.CELLS.CLIENT_USERS.id.name,
        accessor: TABLE.INCIDENTS.CELLS.CLIENT_USERS.id.accessor,
        type: SKELETON_VARIANT.text,
        visible: "false"
      },
      {
        Header: TABLE.INCIDENTS.CELLS.CLIENT_USERS.created.name,
        accessor: TABLE.INCIDENTS.CELLS.CLIENT_USERS.created.accessor,
        filter: "between",
        visible: "true",
        type: SKELETON_VARIANT.text,
        Cell: ({ row, value }: any) =>
          buildCell(row, new FormatDate(value).date)
      },
      {
        Header: TABLE.INCIDENTS.CELLS.CLIENT_USERS.modified.name,
        accessor: TABLE.INCIDENTS.CELLS.CLIENT_USERS.modified.accessor,
        filter: "between",
        visible: "true",
        type: SKELETON_VARIANT.text,
        Cell: ({ row, value }: any) =>
          buildCell(row, new FormatDate(value).date)
      },
      {
        Header: TABLE.INCIDENTS.CELLS.CLIENT_USERS.status.name,
        accessor: TABLE.INCIDENTS.CELLS.CLIENT_USERS.status.accessor,
        filter: "equals",
        visible: "true",
        type: SKELETON_VARIANT.chip,
        Cell: ({ row, value }: any) => buildStatusCell(row, value)
      },
      {
        Header: TABLE.INCIDENTS.CELLS.MDR_USERS.risk.name,
        accessor: TABLE.INCIDENTS.CELLS.MDR_USERS.risk.accessor,
        visible: "false",
        type: SKELETON_VARIANT.text
      }
    ],
    [buildCategoryCell, buildCell, buildSeverityCell, buildStatusCell]
  );

  const providerStructure = useMemo(
    () => [
      {
        Header: TABLE.INCIDENTS.CELLS.MDR_USERS.client.name,
        accessor: TABLE.INCIDENTS.CELLS.MDR_USERS.client.accessor,
        filter: "equals",
        visible: "true",
        type: SKELETON_VARIANT.text,
        Cell: ({ row, value }) => buildCell(row, value)
      },
      ...clientStructure
    ],
    [buildCell, clientStructure]
  );

  const onClickAssign = useCallback((row: any, handlerId: string) => {
    const assignEvent = new CustomEvent(EVENTS.assignIncident, {
      detail: {
        rowIndex: row.index,
        incidentId: row.values.id,
        previousHandlerId: row.values.handler_name,
        handlerId
      }
    });
    window.dispatchEvent(assignEvent);
  }, []);

  const onCheckHeader = useCallback((event: any) => {
    const eventName = event.target.checked
      ? EVENTS.checkAllIncidents
      : EVENTS.uncheckAllIncidents;
    const checkEvent = new CustomEvent(eventName);

    window.dispatchEvent(checkEvent);
  }, []);

  const onClickCheck = useCallback((event: any, row: any) => {
    const eventName = event.target.checked
      ? EVENTS.checkIncident
      : EVENTS.uncheckIncident;

    row.toggleRowSelected();

    const checkEvent = new CustomEvent(eventName, {
      detail: {
        row: row.index,
        id: row.values.id
      }
    });

    window.dispatchEvent(checkEvent);
  }, []);

  const getCellState = useCallback((cell: any) => {
    const state = Object.entries(cell.state).length ? cell.state : null;

    if (!state) {
      return null;
    }

    const type: any = {
      [STATE.incident]: state.data,
      [STATE.avatar]: state.data,
      [STATE.tooltip]: state.data,
      [STATE.handlers]: state.data
    };

    return type[state.type];
  }, []);

  const mdrUsersStructure = useMemo(
    () => [
      {
        Header: () => (
          <input
            type="checkbox"
            ref={headerCheck}
            onChange={(event) => onCheckHeader(event)}
          />
        ),
        accessor: TABLE.INCIDENTS.CELLS.MDR_USERS.check.accessor,
        type: SKELETON_VARIANT.check,
        Cell: ({ row }: any) => {
          const checked = row.isSelected;

          return (
            <input
              type="checkbox"
              value={row.index}
              checked={checked}
              onChange={(event) => onClickCheck(event, row)}
            />
          );
        },
        visible: "true"
      },
      {
        Header: TABLE.INCIDENTS.CELLS.MDR_USERS.client.name,
        accessor: TABLE.INCIDENTS.CELLS.MDR_USERS.client.accessor,
        filter: "equals",
        visible: "true",
        type: SKELETON_VARIANT.text,
        Cell: ({ row, value }: any) => buildCell(row, value)
      },
      {
        Header: TABLE.INCIDENTS.CELLS.MDR_USERS.severity.name,
        accessor: TABLE.INCIDENTS.CELLS.MDR_USERS.severity.accessor,
        type: SKELETON_VARIANT.text,
        Cell: ({ row, value }: any) => buildSeverityCell(row, value),
        visible: "true"
      },
      {
        Header: TABLE.INCIDENTS.CELLS.MDR_USERS.category.name,
        accessor: TABLE.INCIDENTS.CELLS.MDR_USERS.category.accessor,
        type: SKELETON_VARIANT.text,
        Cell: ({ row, value }: any) => buildCategoryCell(row, value),
        visible: "true"
      },
      {
        Header: TABLE.INCIDENTS.CELLS.MDR_USERS.rules.name,
        accessor: TABLE.INCIDENTS.CELLS.MDR_USERS.rules.accessor,
        type: SKELETON_VARIANT.text,
        Cell: ({ cell, value }: any) => {
          const state = getCellState(cell);
          const url = buildRedirectURL(cell.row.values.id);

          const text = value
            ? value.length > 1
              ? `${value[0]} and ${value.length - 1} more`
              : value[0]
            : "";

          return (
            <div className="has-tooltip incidentsRules">
              <a
                className="noDecoration"
                target="_blank"
                href={url}
                rel="noreferrer"
              >
                {text}
              </a>
              {state === ACTIONS.show && (
                <Tooltip type={tooltipTypes.info} top>
                  <ul className="incidentsRules__list">
                    {value.map((rule: string, index: number) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <li className="incidentsRules__list__item" key={index}>
                        {rule}
                      </li>
                    ))}
                  </ul>
                </Tooltip>
              )}
            </div>
          );
        },
        visible: "true"
      },
      {
        Header: TABLE.INCIDENTS.CELLS.MDR_USERS.hostname.name,
        accessor: TABLE.INCIDENTS.CELLS.MDR_USERS.hostname.accessor,
        filter: "equals",
        Cell: ({ row, value }: any) => buildCell(row, value),
        type: SKELETON_VARIANT.text,
        visible: "true"
      },
      {
        Header: TABLE.INCIDENTS.CELLS.MDR_USERS.id.name,
        accessor: TABLE.INCIDENTS.CELLS.MDR_USERS.id.accessor,
        type: SKELETON_VARIANT.text,
        visible: "false"
      },
      {
        Header: TABLE.INCIDENTS.CELLS.MDR_USERS.assigned.name,
        accessor: TABLE.INCIDENTS.CELLS.MDR_USERS.assigned.accessor,
        visible: "true",
        type: SKELETON_VARIANT.avatar,
        Cell: ({ row, cell, value }: any) => {
          const state = getCellState(cell);
          const { handler_id, handler_fullname } = useMemo(
            () => row.original,
            [row.original]
          );
          const userId = useMemo(() => localUser.id, []);
          const handlerList = useMemo(
            () => ({ ...(state?.handlers || {}) }),
            [state]
          );

          const assignTooltipText = handler_fullname || "Assign incident";

          const handlers = useMemo(() => {
            if (!Object.keys(handlerList).length) return [];

            if (Reflect.has(handlerList, handler_id))
              delete handlerList[handler_id];

            if (Reflect.has(handlerList, userId)) {
              delete handlerList[userId];
              const arrayHandlerList = handlersMapper(handlerList);
              arrayHandlerList.unshift({
                id: userId,
                value: handler_id ? "Reassign to me" : "Assign to me"
              });
              return arrayHandlerList;
            }

            return handlersMapper(handlerList);
          }, [handler_id, userId, handlerList]);

          const onHandlerSelected = (handlerId: string) =>
            onClickAssign(row, handlerId);

          const loadingThumbnail: IMenuItem[] = [
            { id: "0", value: "Loading incident handlers..." }
          ];

          return (
            <div className="has-tooltip incidentsAssigned__handler-selector">
              <Menu
                items={handlers.length ? handlers : loadingThumbnail}
                clickAction={onHandlerSelected}
              >
                <Avatar text={value || undefined} />
              </Menu>
              <Tooltip type={tooltipTypes.info} top>
                <p className="incidentsAssigned__tooltip__text">
                  {assignTooltipText}
                </p>
              </Tooltip>
            </div>
          );
        }
      },
      {
        Header: TABLE.INCIDENTS.CELLS.MDR_USERS.created.name,
        accessor: TABLE.INCIDENTS.CELLS.MDR_USERS.created.accessor,
        filter: "between",
        visible: "true",
        type: SKELETON_VARIANT.text,
        Cell: ({ row, value }: any) =>
          buildCell(row, new FormatDate(value).date)
      },
      {
        Header: TABLE.INCIDENTS.CELLS.MDR_USERS.modified.name,
        accessor: TABLE.INCIDENTS.CELLS.MDR_USERS.modified.accessor,
        visible: "true",
        type: SKELETON_VARIANT.text,
        Cell: ({ row, value }: any) =>
          buildCell(row, new FormatDate(value).date)
      },
      {
        Header: TABLE.INCIDENTS.CELLS.MDR_USERS.status.name,
        accessor: TABLE.INCIDENTS.CELLS.MDR_USERS.status.accessor,
        filter: "equals",
        visible: "true",
        type: SKELETON_VARIANT.chip,
        Cell: ({ row, value }: any) => buildStatusCell(row, value)
      },
      {
        Header: TABLE.INCIDENTS.CELLS.MDR_USERS.actions.name,
        accessor: TABLE.INCIDENTS.CELLS.MDR_USERS.actions.accessor,
        visible: "onDemand",
        type: SKELETON_VARIANT.action,
        Cell: ({ row }: any) => {
          const incidentId = row.values.id;
          return <IncidentsContextMenu row={row} id={incidentId} />;
        }
      },
      {
        Header: TABLE.INCIDENTS.CELLS.MDR_USERS.risk.name,
        accessor: TABLE.INCIDENTS.CELLS.MDR_USERS.risk.accessor,
        type: SKELETON_VARIANT.text,
        visible: "false"
      }
    ],
    [
      buildCategoryCell,
      buildCell,
      buildRedirectURL,
      buildSeverityCell,
      buildStatusCell,
      getCellState,
      localUser.id,
      onCheckHeader,
      onClickAssign,
      onClickCheck
    ]
  );

  const closeBatchColumn = useMemo(
    () => [
      {
        Header: <IncidentTableHeaderCheckbox />,
        accessor: TABLE.INCIDENTS.CELLS.MDR_USERS.check.accessor,
        headerClassName: "tableHeader--centered",
        cellClassName: "tableCell--centered",
        type: SKELETON_VARIANT.check,
        Cell: ({ row }: any) => {
          const { id: eventId, can_be_closed } = row?.original || {};
          const { onSelectCheck, selectedIncidents } = useContext(
            IPantherIncidentListContext
          );
          const checked = useMemo(
            () => selectedIncidents.includes(eventId),
            [selectedIncidents, eventId]
          );

          return (
            <input
              type="checkbox"
              value={row.index}
              checked={checked && can_be_closed}
              disabled={!can_be_closed}
              onChange={() => onSelectCheck(eventId)}
            />
          );
        },
        visible: "true"
      }
    ],
    []
  );

  const pantherStructure = useMemo(() => {
    const columns = [
      {
        Header: TABLE.INCIDENTS.CELLS.MDR_USERS.client.name,
        accessor: TABLE.INCIDENTS.CELLS.MDR_USERS.client.accessor,
        filter: "equals",
        visible: "true",
        type: SKELETON_VARIANT.text,
        Cell: ({ row, value }: any) => buildCell(row, value, true)
      },
      {
        Header: TABLE.INCIDENTS.CELLS.MDR_USERS.severity.name,
        accessor: TABLE.INCIDENTS.CELLS.MDR_USERS.severity.accessor,
        type: SKELETON_VARIANT.text,
        Cell: ({ row, value }: any) => buildSeverityCell(row, value, true),
        visible: "true"
      },
      {
        Header: TABLE.INCIDENTS.CELLS.MDR_USERS.category.name,
        accessor: TABLE.INCIDENTS.CELLS.MDR_USERS.category.accessor,
        type: SKELETON_VARIANT.text,
        Cell: ({ row, value }: any) => buildCategoryCell(row, value, true),
        visible: "true"
      },
      {
        Header: TABLE.INCIDENTS.CELLS.MDR_USERS.rules.name,
        accessor: TABLE.INCIDENTS.CELLS.MDR_USERS.rules.accessor,
        type: SKELETON_VARIANT.text,
        CellWrapper: ({
          cell: { value },
          className,
          children,
          onClick
        }: any) => (
          <td className={`${className} `} onClick={onClick}>
            <div className="has-tooltip">
              {value?.length > 1 && (
                <Tooltip type={tooltipTypes.info} top>
                  <ul className="incidentsRules__list">
                    {value?.map((rule: string, index: number) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <li className="incidentsRules__list__item" key={index}>
                        {rule}
                      </li>
                    ))}
                  </ul>
                </Tooltip>
              )}
              {children}
            </div>
          </td>
        ),
        Cell: ({ cell, value }: any) => {
          // const state = getCellState(cell);
          const url = buildRedirectURL(cell.row.values.id, true);

          const text = value
            ? value.length > 1
              ? `${value[0]} and ${value.length - 1} more`
              : value[0]
            : "";

          return (
            <div className="incidentsRules">
              <a
                className="noDecoration"
                target="_blank"
                href={url}
                rel="noreferrer"
              >
                {text}
              </a>
            </div>
          );
        },
        visible: "true"
      },
      {
        Header: TABLE.INCIDENTS.CELLS.MDR_USERS.sensor.name,
        accessor: TABLE.INCIDENTS.CELLS.MDR_USERS.sensor.accessor,
        filter: "equals",
        Cell: ({ row, value }: any) => buildCell(row, value, true),
        type: SKELETON_VARIANT.text,
        visible: "true"
      },
      {
        Header: TABLE.INCIDENTS.CELLS.MDR_USERS.id.name,
        accessor: TABLE.INCIDENTS.CELLS.MDR_USERS.id.accessor,
        type: SKELETON_VARIANT.text,
        visible: "false"
      },
      {
        Header: TABLE.INCIDENTS.CELLS.MDR_USERS.assigned.name,
        accessor: TABLE.INCIDENTS.CELLS.MDR_USERS.assigned.accessor,
        visible: "true",
        type: SKELETON_VARIANT.avatar,
        Cell: ({ row, value }: any) => {
          const { handlers, onAssign } = useContext(
            IPantherIncidentListContext
          );

          const { handler_id, handler_fullname } = useMemo(
            () => row.original,
            [row.original]
          );
          const userId = useMemo(() => localUser.id, []);

          const assignTooltipText = handler_fullname || "Assign incident";

          const mappedHandlers = useMemo(() => {
            const handlerListCopy = { ...(handlers || {}) };
            if (!Object.keys(handlerListCopy).length) return [];

            if (Reflect.has(handlerListCopy, handler_id))
              delete handlerListCopy[handler_id];

            if (Reflect.has(handlerListCopy, userId)) {
              delete handlerListCopy[userId];
              const arrayHandlerList = handlersMapper(handlerListCopy);
              arrayHandlerList.unshift({
                id: userId,
                value: handler_id ? "Reassign to me" : "Assign to me"
              });
              return arrayHandlerList;
            }

            return handlersMapper(handlerListCopy);
          }, [handler_id, handlers, userId]);

          const loadingThumbnail: IMenuItem[] = [
            { id: "0", value: "Loading incident handlers..." }
          ];

          const onClickHandler = useCallback(
            async (selectedHandlerId: any) => {
              await onAssign({
                rowIndex: row.index,
                incidentId: row.values.id,
                previousHandlerId: row.values.handler_name,
                handlerId: selectedHandlerId
              });
            },
            [onAssign, row.index, row.values.handler_name, row.values.id]
          );

          return (
            <div className="has-tooltip incidentsAssigned__handler-selector">
              <Menu
                items={
                  mappedHandlers.length ? mappedHandlers : loadingThumbnail
                }
                clickAction={onClickHandler}
              >
                <Avatar text={value || undefined} />
              </Menu>
              <Tooltip type={tooltipTypes.info} top>
                <p className="incidentsAssigned__tooltip__text">
                  {assignTooltipText}
                </p>
              </Tooltip>
            </div>
          );
        }
      },
      {
        Header: TABLE.INCIDENTS.CELLS.MDR_USERS.created.name,
        accessor: TABLE.INCIDENTS.CELLS.MDR_USERS.created.accessor,
        filter: "between",
        visible: "true",
        type: SKELETON_VARIANT.text,
        Cell: ({ row, value }: any) =>
          buildCell(row, new FormatDate(value).date, true)
      },
      {
        Header: TABLE.INCIDENTS.CELLS.MDR_USERS.modified.name,
        accessor: TABLE.INCIDENTS.CELLS.MDR_USERS.modified.accessor,
        visible: "true",
        type: SKELETON_VARIANT.text,
        Cell: ({ row, value }: any) =>
          buildCell(row, new FormatDate(value).date)
      },
      {
        Header: TABLE.INCIDENTS.CELLS.MDR_USERS.status.name,
        accessor: TABLE.INCIDENTS.CELLS.MDR_USERS.status.accessor,
        filter: "equals",
        visible: "true",
        type: SKELETON_VARIANT.chip,
        Cell: ({ row, value }: any) => buildStatusCell(row, value, true)
      },
      {
        Header: TABLE.INCIDENTS.CELLS.MDR_USERS.actions.name,
        accessor: TABLE.INCIDENTS.CELLS.MDR_USERS.actions.accessor,
        visible: "onDemand",
        type: SKELETON_VARIANT.action,
        Cell: ({ row }: any) => {
          const incidentId = row?.values?.id;
          return (
            <IncidentsContextMenu row={row} id={incidentId} switchContext />
          );
        }
      },
      {
        Header: TABLE.INCIDENTS.CELLS.MDR_USERS.risk.name,
        accessor: TABLE.INCIDENTS.CELLS.MDR_USERS.risk.accessor,
        type: SKELETON_VARIANT.text,
        visible: "false"
      }
    ];
    return enableBatchClose ? [...closeBatchColumn, ...columns] : columns;
  }, [
    buildCategoryCell,
    buildCell,
    buildRedirectURL,
    buildSeverityCell,
    buildStatusCell,
    closeBatchColumn,
    enableBatchClose,
    localUser.id
  ]);

  return {
    clientStructure,
    mdrUsersStructure,
    providerStructure,
    pantherStructure,
    setHeaderCheckState
  };
}
