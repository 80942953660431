/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from "react";
import "./table.scss";
import Pagination from "./pagination/pagination";
import {
  INFORMATION_ICON,
  ORG_DEFAULT_ROWS_PER_PAGE
} from "../../constants/common.constants";
import Skeleton from "../skeleton/skeleton";

type IHeaderProps = {
  visible: string;
  children: React.JSX.Element;
  headerClassName?: string;
};

type ICellProps = {
  visible: string;
  columnId?: string;
  rowIndex?: number;
  children?: React.JSX.Element;
  onMouseOver?: any;
  onMouseOut?: any;
  onClick?: any;
  CellWrapper?: any;
  cell?: any;
  cellClassName?: string;
};

type IRowProps = {
  index?: number;
  onClickCell?: any;
  onMouseOver?: any;
  onMouseOut?: any;
  header?: boolean;
  cells: any[];
  isLoadingData: boolean;
};

type ITableProps = {
  pagination?: boolean;
  instance: any;
  onClickCell?: any;
  onMouseOver?: any;
  onMouseOut?: any;
  isLoadingData: boolean;
};

function Header({ visible, children, headerClassName = "" }: IHeaderProps) {
  const classRoot = "tableHeaderData";

  let className = classRoot;
  className +=
    visible === "true"
      ? ` ${classRoot}--visible`
      : visible === "onDemand"
      ? ` ${classRoot}--onDemand`
      : ` ${classRoot}--hidden`;

  return (
    <th className={`${className} ${headerClassName}`}>
      {visible === "true" && <>{children}</>}
    </th>
  );
}

function Cell({
  visible,
  columnId,
  rowIndex,
  children,
  CellWrapper,
  onMouseOver = () => {},
  onMouseOut = () => {},
  onClick = () => {},
  cell,
  cellClassName = ""
}: ICellProps) {
  const [showCell, setShowCell] = useState(false);

  const classRoot = "tableCell";

  let className = classRoot;
  // TODO: make this readable
  className +=
    visible === "true"
      ? ` ${classRoot}--visible`
      : visible === "onDemand"
      ? showCell
        ? ` ${classRoot}--visible`
        : ` ${classRoot}--onDemand`
      : ` ${classRoot}--hidden`;

  const onMouseOverAction = () => {
    setShowCell(onMouseOver(columnId));
  };

  const onMouseOutAction = () => {
    setShowCell(onMouseOut(columnId));
  };

  const onClickAction = (e: any) => {
    e.preventDefault();
    onClick(columnId);
  };

  if (CellWrapper) {
    return (
      <CellWrapper
        className={`${className} ${cellClassName}`}
        data-index={rowIndex}
        cell={cell}
        onMouseOver={onMouseOverAction}
        onMouseOut={onMouseOutAction}
        onClick={onClickAction}
      >
        {children}
      </CellWrapper>
    );
  }

  return (
    <td
      className={`${className} ${cellClassName}`}
      data-index={rowIndex}
      onClick={onClickAction}
      onMouseOver={onMouseOverAction}
      onMouseOut={onMouseOutAction}
    >
      {children}
    </td>
  );
}

function Row({
  index,
  onClickCell,
  onMouseOver,
  onMouseOut,
  header,
  cells,
  isLoadingData
}: IRowProps) {
  const [hover, setHover] = useState(false);

  const onClickCellAction = (columnId: string) => onClickCell(index, columnId);

  const onMouseOverHover = () => setHover(true);

  const onMouseOuthover = () => setHover(false);

  const onMouseOverCell = (columnId: string) => onMouseOver(index, columnId);

  const onMouseOutCell = (columnId: string) => onMouseOut(index, columnId);

  return (
    <tr
      className={header ? "tableHeader" : "tableRow"}
      onMouseOver={onMouseOverHover}
      onMouseOut={onMouseOuthover}
    >
      {header
        ? cells.map((column) => (
            <Header
              {...column.getHeaderProps()}
              isLoadingData
              id={column.id}
              visible={column.visible}
              headerClassName={column.headerClassName || ""}
            >
              <h5>{column.render("Header")}</h5>
            </Header>
          ))
        : cells.map((cell) => {
            const visible =
              cell?.column?.visible === "onDemand"
                ? hover
                  ? "true"
                  : "onDemand"
                : cell?.column?.visible;

            return isLoadingData ? (
              <Cell key={cell.id} visible={cell.visible}>
                <Skeleton variant={cell.type} />
              </Cell>
            ) : (
              <Cell
                {...cell.getCellProps()}
                rowIndex={index}
                columnId={cell.column.id}
                onClick={onClickCellAction}
                onMouseOver={onMouseOverCell}
                onMouseOut={onMouseOutCell}
                visible={visible}
                CellWrapper={cell?.column?.CellWrapper}
                cellClassName={cell?.column?.cellClassName || ""}
                cell={cell}
              >
                {cell.render("Cell")}
              </Cell>
            );
          })}
    </tr>
  );
}

export default function Table({
  pagination,
  instance,
  onClickCell,
  onMouseOver,
  onMouseOut,
  isLoadingData
}: ITableProps) {
  const iterator = pagination ? instance.page : instance.rows;

  const pages = {
    current: instance.state.pageIndex + 1,
    total: instance.pageOptions.length
  };

  const pageRows = {
    start: instance.state.pageIndex * instance.state.pageSize + 1,
    finish: instance.canNextPage
      ? instance.state.pageIndex * instance.state.pageSize +
        instance.state.pageSize
      : instance.rows.length,
    total: instance.rows.length
  };

  const onClickCellAction = (rowIndex: number, columnId: string) =>
    onClickCell
      ? onClickCell(
          rowIndex + instance.state.pageIndex * ORG_DEFAULT_ROWS_PER_PAGE,
          columnId
        )
      : false;

  const onMouseOverAction = (rowIndex: number, columnId: string) => {
    if (onMouseOver) {
      return onMouseOver(rowIndex, columnId);
    }
    return false;
  };

  const onMouseOutAction = (rowIndex: number, columnId: string) => {
    if (onMouseOut) {
      return onMouseOut(rowIndex, columnId);
    }
    return false;
  };

  const onPreviousPage = () => instance.previousPage();

  const onNextPage = () => instance.nextPage();

  const onGotoPage = (page: number) => instance.gotoPage(page - 1);

  return (
    <div className="table-section">
      <table {...instance.getTableProps()} className="tableContainer">
        <thead>
          {instance.headerGroups.map((headerGroup: any) => (
            <Row
              {...headerGroup.getHeaderGroupProps()}
              cells={headerGroup.headers}
              header
            />
          ))}
        </thead>
        <tbody {...instance.getTableBodyProps()}>
          {!!iterator.length &&
            iterator.map((row: any, index: number) => (
              <Row
                {...row.getRowProps()}
                cells={row.cells}
                index={index}
                onClickCell={onClickCellAction}
                onMouseOver={onMouseOverAction}
                onMouseOut={onMouseOutAction}
              />
            ))}
          {isLoadingData &&
            instance.headerGroups.map((headerGroup: any) => (
              <Row key="skeleton" isLoadingData cells={headerGroup.headers} />
            ))}
        </tbody>
      </table>
      {!isLoadingData && !iterator.length && (
        <div className="no-data-container">
          <img
            className="no-data-container__image"
            src={INFORMATION_ICON}
            alt=""
          />
          <h5 className="no-data-container__title">No data available</h5>
          <p className="no-data-container__description">
            There is no data that matches the selected time range or filters.
          </p>
        </div>
      )}
      {pagination && !isLoadingData && !!iterator.length && (
        <Pagination
          pages={pages}
          rows={pageRows}
          onPrevious={onPreviousPage}
          onNext={onNextPage}
          onGoTo={onGotoPage}
        />
      )}
    </div>
  );
}
