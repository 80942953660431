import React, { useCallback, useContext, useEffect, useMemo } from "react";
import "./incidentHeaderActionBarClientUser.scss";
import * as Yup from "yup";
import { Field, Formik } from "formik";
import ActionBar from "../../components/actionBar/actionBar";
import Button from "../../components/button/button";
import { VARIANT, WIDTH } from "../../constants/common.constants";
import { IncidentContext } from "../incident";
import ModalContext from "../../contexts/modal.context";
import {
  ICloseFormProps,
  IIncidentHeaderActionBarClientUserProps
} from "./incidentHeader.types";
import TextArea from "../../components/textArea/textArea";
import { IncidentInfoProviderContext } from "../incidentInformationProvider";

const formId = "closeIncidentForm";

const validationSchema = Yup.object().shape({
  reason: Yup.string().required("Choose an option")
});

const validationSchemaWitCloseDisposition = Yup.object().shape({
  reason: Yup.string().required("Choose an option"),
  closeFinalDisposition: Yup.string().required(
    "Please provide a reason for closure"
  )
});

function CloseFormWithCloseFinalDisposition({
  formik: { isValid, handleSubmit, handleChange, errors, setFieldValue },
  catalog
}: ICloseFormProps) {
  const closeReasons = useMemo(
    () =>
      Reflect.has(catalog, "close_reason")
        ? Object.entries(catalog.close_reason)
        : [],
    [catalog]
  );
  const { setActionDisabled } = useContext(ModalContext);

  useEffect(() => setActionDisabled(!isValid), [setActionDisabled, isValid]);

  return (
    <form onSubmit={handleSubmit} id={formId}>
      <h5>Select close status</h5>
      {closeReasons.map(([id, text]) => (
        <section key={id} className="incidentsCloseContainer__status">
          <p>{text}</p>
          <input
            className="incidentsCloseContainer__status__input"
            type="radio"
            name="reason"
            value={id}
            onChange={handleChange}
          />
        </section>
      ))}
      <section className="incidentsCloseContainer__status">
        <Field
          name="closeFinalDisposition"
          label="Reason for Closure"
          error={errors.closeFinalDisposition}
          touched
          width={WIDTH.max}
          component={TextArea}
          onChange={(event: any) =>
            setFieldValue("closeFinalDisposition", event?.target?.value || "")
          }
        />
      </section>
    </form>
  );
}

function CloseForm({
  formik: { isValid, handleSubmit, handleChange },
  catalog
}: ICloseFormProps) {
  const closeReasons = useMemo(
    () =>
      Reflect.has(catalog, "close_reason")
        ? Object.entries(catalog.close_reason)
        : [],
    [catalog]
  );
  const { setActionDisabled } = useContext(ModalContext);

  useEffect(() => setActionDisabled(!isValid), [setActionDisabled, isValid]);

  return (
    <form onSubmit={handleSubmit} id={formId}>
      <h5>Select close status</h5>
      {closeReasons.map(([id, text]) => (
        <section key={id} className="incidentsCloseContainer__status">
          <p>{text}</p>
          <input
            className="incidentsCloseContainer__status__input"
            type="radio"
            name="reason"
            value={id}
            onChange={handleChange}
          />
        </section>
      ))}
      <div className="incidentsCloseContainer__divider" />
    </form>
  );
}

export default function IncidentHeaderActionBarClientUser({
  closeAllowed,
  onClose
}: IIncidentHeaderActionBarClientUserProps) {
  const { showModal } = useContext(ModalContext);
  const { catalog } = useContext(IncidentContext);

  const { isSIEM } = useContext(IncidentInfoProviderContext);

  const onCloseModal = useCallback(
    () =>
      showModal({
        title: "Close Incident",
        content: (
          <Formik
            initialValues={{ reason: "" }}
            onSubmit={(values: any) => {
              onClose(values.reason, values.closeFinalDisposition);
            }}
            validationSchema={
              isSIEM ? validationSchemaWitCloseDisposition : validationSchema
            }
            validateOnMount
          >
            {(formik) =>
              isSIEM ? (
                <CloseFormWithCloseFinalDisposition
                  catalog={catalog}
                  formik={formik}
                />
              ) : (
                <CloseForm catalog={catalog} formik={formik} />
              )
            }
          </Formik>
        ),
        formId
      }),
    [showModal, isSIEM, onClose, catalog]
  );

  return (
    <ActionBar>
      <Button
        variant={VARIANT.primary}
        disabled={!closeAllowed}
        onClick={onCloseModal}
      >
        Close Incident
      </Button>
    </ActionBar>
  );
}
