import { useFlags } from "launchdarkly-react-client-sdk";
import React, { createContext, useMemo } from "react";

export type FeatureFlagContext = {
  enableBatchClose: boolean;
};
const featureFlagsContext = createContext<FeatureFlagContext>(
  {} as FeatureFlagContext
);

export function convertFeatureFlagStringToBoolean(
  featureFlagString = ""
): boolean {
  return featureFlagString?.toLocaleLowerCase() === "true" || false;
}

export function FeatureFlagsProvider({ children }: any) {
  const { enableBatchClose } = useFlags();
  const flags = useMemo(() => ({ enableBatchClose }), [enableBatchClose]);
  return (
    <featureFlagsContext.Provider value={flags}>
      {children}
    </featureFlagsContext.Provider>
  );
}

export default featureFlagsContext;
